import { OrderItemBlack } from "components";
import { IBuildsFields } from "types";

const Orders2024Section: React.FC<{
  buildList: IBuildsFields[];
}> = ({ buildList }) => {
  return (
    <section className="bg-black py-section">
      <div className="container flex flex-col items-center max-w-[810px]">
        <h1 className="lg:heading-2 text-[34px] text-center text-white lg:mb-[53px] mb-[40px]">
          The entire 2025 production is sold out
        </h1>
        {!!buildList?.length ? (
          <div className="w-full max-w-4xl">
            {buildList.map(({ build2023 }, index) => (
              <ul key={index}>
                {build2023.map((order) => (
                  <OrderItemBlack key={order.fields.number} {...order.fields} />
                ))}
              </ul>
            ))}
          </div>
        ) : (
          <p className="text-white text-lg">No orders found</p>
        )}
      </div>
    </section>
  );
};

export default Orders2024Section;
